import Typography from "typography"
import gray from "gray-percentage"
import {
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from "typography-breakpoint-constants"
import verticalRhythm from "compass-vertical-rhythm"

// NOTE(tcnksm): Since I want to customize multiple values, copying the entire values and fixing
// is better than overwrite. The original is Parnassus theme [1].
// 
// [1]: https://github.com/KyleAMathews/typography.js/tree/master/packages/typography-theme-parnassus
const typography = new Typography({
  baseFontSize: "17px",
  baseLineHeight: 1.82,
  scaleRatio: 2.25,
  googleFonts: [
    {
      name: "Inter",
      styles: ["400", "400i", "700"],
    },  
  ],
  headerFontFamily: ["Inter"],
  bodyFontFamily: ["Inter"],
  headerColor: "hsla(0,0%,0%,0.9)",
  bodyColor: "hsla(0,0%,0%,0.8)",
  headerWeight: 800,
  bodyWeight: 400,
  boldWeight: 700,
  
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
    const vr = verticalRhythm({
      baseFontSize: "15px",
      baseLineHeight: "27.35px",
    })

    return {
      // NOTE(tcnksm): Added this to adjust itemize margins.
      // The default settings are too large.
      li: {
        marginBottom: rhythm(0.05),
      },

      // NOTE(tcnksm): Center audio player.
      // Maybe this should not be here ....
      audio: {        
        width: "400px",
        height: "54px",
        maxWidth: "100%",
        margin: "0 auto",
        display: "block",
      },

      // NOTE(tcnksm)
      "h1,h2,h3": {
        marginTop: rhythm(2),
      },

      "h4,h5,h6": {
        marginTop: rhythm(1),
      },

      // NOTE(tcnksm): The followings are same as original.
      a: {
        color: "#375c85",
        textDecoration: "none",
        boxShadow: "0 1px 0 0 currentColor",
      },
      "a:hover,a:active": {
        boxShadow: "none",
      },
      blockquote: {
        ...scale(1 / 5),
        color: gray(41),
        paddingLeft: rhythm(18 / 16),
        marginLeft: 0,
        borderLeft: `${rhythm(6 / 16)} solid`,
        borderColor: gray(90),
      },
      "blockquote > :last-child": {
        marginBottom: 0,
      },
      "blockquote cite": {
        ...adjustFontSizeTo(options.baseFontSize),
        color: options.bodyColor,
        fontWeight: options.bodyWeight,
      },
      "blockquote cite:before": {
        content: '"— "',
      },
      [MOBILE_MEDIA_QUERY]: {
        blockquote: {
          marginLeft: rhythm(-3 / 4),
          marginRight: 0,
          borderLeft: `${rhythm(3 / 16)} solid`,
          borderColor: gray(90),
          paddingLeft: rhythm(9 / 16),
        },
      },
      [TABLET_MEDIA_QUERY]: {
        html: {
          ...vr.establishBaseline(),
        },
      },
    }
  },
})

export default typography
export const rhythm = typography.rhythm
