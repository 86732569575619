import React from "react"
import { css } from "@emotion/react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 700px;
        padding: ${rhythm(2)};
        padding-top: ${rhythm(1.5)};
      `}
    >
      <Link to={`/`}>
        <h1>
          {data.site.siteMetadata.title}
        </h1>
      </Link>

      <ul
        style={{ listStyle: `none`, float: `left` }}
        css={css`
        margin-left: 0;
      `}

      >        
        <ListLink to="/about">About</ListLink>
        <ListLink to="/">Episodes</ListLink>
        <ListLink to="/subscribe">Subscribe</ListLink>
        <ListLink to="/feedback">Feedback</ListLink>
      </ul>

      <div
         css={css`
         padding-top: ${rhythm(1)};
       `}
      >
        {children}
      </div>
      {/* <footer>
        Copyright © {new Date().getFullYear()} by <a href="https://twitter.com/deeeet">Taichi Nakashima</a> and <a href="https://twitter.com/rrreeeyyy">Ryota Yoshikawa</a>
        </footer> */}
    </div>
  )
}
